.blinking {
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: blinking;
    animation-name: blinking;
    left: 3px;
    line-height: inherit;
    opacity: 1;
}

@keyframes blinking {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.bounce {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: bounce;
    animation-name: bounce;
    left: 3px;
    line-height: inherit;
    opacity: 1;
    animation-timing-function: linear;
}

@keyframes bounce {

    0% {
        transform: translate(0px, 0px);
    }

    25% {
        transform:  translate(-4px, 0px);
    }

    50% {
        transform: translate(0px, 0px);
    }


    75% {
        transform: translate(4px, 0px);
    }

    100% {
        transform: translate(0px, 0px);
    }

}